import { onDarkBase } from "@biom3/design-tokens";
import { Body, Heading } from "@biom3/react";
import Link from "next/link";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UnprotectedLayout } from "@/components";
import { analytics } from "@analytics";

export default function NotFound() {
  const { t } = useTranslation();

  useEffect(() => {
    analytics.page("404", {
      screen: "404",
      userJourney: "Profile",
    });
  }, []);

  return (
    <UnprotectedLayout>
      <Heading testId="not_found_heading">{t("not_found_heading")}</Heading>
      <Body testId="not_found_body">{t("not_found_body")}</Body>
      <Body testId="not_found_link">
        <Link style={{ color: onDarkBase.color.text.link.primary }} href="/">
          {t("not_found_link")}
        </Link>
      </Body>
    </UnprotectedLayout>
  );
}
